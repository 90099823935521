import {
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from 'kitchen/router'
import React from 'react'
import { NotFound } from './components/not-found'
import { Path, Match } from './constants'
import { RequireAuth } from './features/protected/require-auth'

const AuthPage = React.lazy(() =>
  import('./features/auth/auth-page').then((mod) => ({
    default: mod.AuthPage,
  }))
)

const CompaniesRoute = React.lazy(() =>
  import('./features/data-browser/company/companies-page').then((mod) => ({
    default: mod.CompaniesPage,
  }))
)

const CompanyRoute = React.lazy(() =>
  import('./features/data-browser/company/company-details').then((mod) => ({
    default: mod.CompanyDetailsPage,
  }))
)

const PayrunsRoute = React.lazy(() =>
  import('./features/data-browser/payruns/payruns-page').then((mod) => ({
    default: mod.PayrunsPage,
  }))
)

const PayrunRoute = React.lazy(() =>
  import('./features/data-browser/payruns/payrun-page').then((mod) => ({
    default: mod.PayrunPage,
  }))
)

const TopupsRoute = React.lazy(() =>
  import('./features/data-browser/topups/topups-page').then((mod) => ({
    default: mod.TopupsPage,
  }))
)

const PayoutsRoute = React.lazy(() =>
  import('./features/data-browser/payouts/payouts-page').then((mod) => ({
    default: mod.PayoutsPage,
  }))
)

const ComplianceAlertsRoute = React.lazy(() =>
  import('./features/data-browser/compliance-alerts/compliance-alerts-page').then(
    (mod) => ({
      default: mod.ComplianceAlertsPage,
    })
  )
)

const ComplianceAlertDetailsRoute = React.lazy(() =>
  import('./features/data-browser/compliance-alerts/compliance-alert-details-page').then(
    (mod) => ({
      default: mod.ComplianceAlertDetailsPage,
    })
  )
)

const ToolsRoute = React.lazy(() =>
  import('./features/data-browser/tools/tools-page').then((mod) => ({
    default: mod.ToolsPage,
  }))
)

const ActivitiesRoute = React.lazy(() =>
  import('./features/data-browser/logs/activities-page').then((mod) => ({
    default: mod.ActivitiesPage,
  }))
)

const SettingsRoute = React.lazy(() =>
  import('./features/data-browser/settings/settings-page').then((mod) => ({
    default: mod.SettingsPage,
  }))
)

const InvoiceDetailsRoute = React.lazy(() =>
  import('./features/data-browser/payruns/invoices/invoice-details-page').then((mod) => ({
    default: mod.InvoiceDetailsPage,
  }))
)

const CompanyDetailsRoutes = () => {
  return (
    <Routes>
      <Route index element={<CompaniesRoute />} />
      <Route path={Match.COMPANY_ID} element={<CompanyRoute />} />
    </Routes>
  )
}

const PayrunsRoutes = () => {
  return (
    <Routes>
      <Route index element={<PayrunsRoute />} />
      <Route path={Match.PAYRUN_ID} element={<PayrunRoute />} />
    </Routes>
  )
}

const InvoicesRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={Path.PAYRUNS} replace />} />
      <Route path={Match.INVOICE_ID} element={<InvoiceDetailsRoute />} />
    </Routes>
  )
}

const ComplianceAlertsRoutes = () => {
  return (
    <Routes>
      <Route index element={<ComplianceAlertsRoute />} />
      <Route path={Match.COMPLIANCE_ALERT_ID} element={<ComplianceAlertDetailsRoute />} />
    </Routes>
  )
}

const Root = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={Path.COMPANIES} replace />} />
      <Route path={Match.AUTH} element={<AuthPage />} />
      <Route path="data/companies" element={<Navigate to={Path.COMPANIES} replace />} />
      <Route path="data/payruns" element={<Navigate to={Path.PAYRUNS} replace />} />
      <Route path="data/payruns/:payrunId" element={<PayrunRoute />} />
      <Route path="data/compliance-alerts" element={<ComplianceAlertsRoute />} />
      <Route path="data/tools" element={<Navigate to={Path.TOOLS} replace />} />
      <Route path="data/activities" element={<Navigate to={Path.ACTIVITIES} replace />} />
      <Route path="data/settings" element={<Navigate to={Path.SETTINGS} replace />} />
      <Route
        path={Match.COMPANIES}
        element={
          <RequireAuth>
            <CompanyDetailsRoutes />
          </RequireAuth>
        }
      />
      <Route
        path={Match.PAYRUNS}
        element={
          <RequireAuth>
            <PayrunsRoutes />
          </RequireAuth>
        }
      />
      <Route
        path={Match.INVOICES}
        element={
          <RequireAuth>
            <InvoicesRoutes />
          </RequireAuth>
        }
      />
      <Route
        path={Match.TOPUPS}
        element={
          <RequireAuth>
            <Routes>
              <Route index element={<TopupsRoute />} />
            </Routes>
          </RequireAuth>
        }
      />
      <Route
        path={Match.PAYOUTS}
        element={
          <RequireAuth>
            <PayoutsRoute />
          </RequireAuth>
        }
      />
      <Route
        path={Match.TOOLS}
        element={
          <RequireAuth>
            <ToolsRoute />
          </RequireAuth>
        }
      />
      <Route
        path={Match.ACTIVITIES}
        element={
          <RequireAuth>
            <ActivitiesRoute />
          </RequireAuth>
        }
      />
      <Route
        path={Match.SETTINGS}
        element={
          <RequireAuth>
            <SettingsRoute />
          </RequireAuth>
        }
      />
      <Route
        path={Match.COMPLIANCE_ALERTS}
        element={
          <RequireAuth>
            <ComplianceAlertsRoutes />
          </RequireAuth>
        }
      />
      <Route path={Match.ALL} element={<NotFound />} />
    </Routes>
  )
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <Root />,
    ErrorBoundary: () => {
      throw useRouteError()
    },
  },
])

export const BORoutes = () => {
  return <RouterProvider router={router} />
}
