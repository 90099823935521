import type { IntlShape } from 'react-intl'
import { createIntl, createIntlCache } from 'react-intl'

let intl: IntlShape | null = null

const cache = createIntlCache()

export const initIntl = (locale: string, messages: Record<string, string>) => {
  intl = createIntl({ locale, messages }, cache)
}

export const getIntl = (): IntlShape => {
  if (intl === null) {
    throw new Error('Intl is not initialized. Call initIntl first.')
  }
  return intl
}
